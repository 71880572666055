import { useContext } from 'react';
import TempApprovedFounderDashboard from './FounderDashboards/TempApprovedFounderDashboard';
import PendingFounderDashboard from './FounderDashboards/PendingFounderDashboard';
import TempRejectedFounderDashboard from './FounderDashboards/TempRejectedFounderDashboard';
import TempUnsubmittedFounderDashboard from './FounderDashboards/TempUnsubmittedFounderDashboard';
import TempSubmittedFounderDashboard from './FounderDashboards/TempSubmittedDashboard';
import ApprovedFounderDashboard from './FounderDashboards/ApprovedFounderDashboard';
import TempPendingFounderDashboard from './FounderDashboards/PendingFounderDashboard';
import RejectedFounderDashboard from './FounderDashboards/RejectedFounderDashboard';
import UnsubmittedFounderDashboard from './FounderDashboards/UnsubmittedFounderDashboard';
import SubmittedFounderDashboard from './FounderDashboards/SubmittedFounderDashboard';
import ExpertInviteDashboard from './FounderDashboards/ExpertInviteDashboard';
import ExpertMetDashboard from './FounderDashboards/ExpertMetDashboard';
import { AuthContext, AuthContextType, useAsync } from '../../utilities/customHooks';
import { getfounderDashboardDetails } from '../../services/founderService';
import LoadingIndicator from '../../Components/LoadingIndicator';
import ErrorIndicator from '../../Components/ErrorIndicator';
import * as T from "../../utilities/frontendTypes";
import PreSowDashboard from './FounderDashboards/PreSowDashboard';
import { checkUrlAndModifySpan } from '../../utilities/helperFunctions';
import TempRejectedReapplyFounderDashboard from './FounderDashboards/TempRejectedReapplyFounderDashboard';
import TempEditRequestFounderDashboard from './FounderDashboards/TempEditRequestFounderDashboard';


function FounderDashboard() {
  const { memberApplicationStatus } = useContext(AuthContext) as AuthContextType;
  const founderDashboardDetailsAsync = useAsync(() => getfounderDashboardDetails(), []);

  if (founderDashboardDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDashboardDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const foundDeat = founderDashboardDetailsAsync.value.dashboardDetails;

    //dashboard fix which is stupid but works  
    const menuFix = () => {
      const menu = document.getElementsByClassName('sideMenu');
      Array.from(menu).forEach(element => {
        element.classList.add('sideMenuCollapsed');
      });
      const hide = document.getElementsByClassName('mobileHide');
      Array.from(hide).forEach(element => {
        element.classList.add('profileHide');
      });
      const headerhide = document.getElementsByClassName('profilePageHide');
      Array.from(headerhide).forEach(element => {
        element.classList.add('profileHide');
      });
    }
  
      const currentUrl = window.location.pathname; 
      checkUrlAndModifySpan(currentUrl)
      if (currentUrl === '/dashboard/profile' || currentUrl.includes('/dashboard/memberships')) {
        menuFix();
      } else if (currentUrl === '/dashboard/program-details') {
        const menu = document.getElementsByClassName('sideMenu');
        Array.from(menu).forEach(element => {
          element.classList.remove('sideMenuCollapsed');
        });
        const hide = document.getElementsByClassName('mobileHide');
        Array.from(hide).forEach(element => {
          element.classList.remove('profileHide');
        });
        const headerhide = document.getElementsByClassName('profilePageHide');
        Array.from(headerhide).forEach(element => {
          element.classList.add('profileHide');
        });
      } else {
        const menu = document.getElementsByClassName('sideMenu');
        Array.from(menu).forEach(element => {
          element.classList.remove('sideMenuCollapsed');
        });
        const hide = document.getElementsByClassName('mobileHide');
        Array.from(hide).forEach(element => {
          element.classList.remove('profileHide');
        });
        const headerhide = document.getElementsByClassName('profilePageHide');
        Array.from(headerhide).forEach(element => {
          element.classList.remove('profileHide');
        });
      }

      console.log(memberApplicationStatus)

  const getFounderDashboardByApplicationStatus = () => {
    // switch (memberApplicationStatus) {
    //   case "approved":
    //     switch (foundDeat.founderStatus) {
    //       case "n/a":
    //         return <ApprovedFounderDashboard />;
    //       case "ipExpertInvite":
    //         return <ExpertInviteDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       case "ipExpertInProgress":
    //       case "ipExpertRecommendation":
    //           return <ExpertMetDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       case "sowRequested":
    //         return <PreSowDashboard companyNameForDashboard={foundDeat.companyName} tier={3} percentage={20} firm={"Roland LLP"}/>;
    //       default:
    //         return <ApprovedFounderDashboard />;
    //     }
    //   case "flagged":  
    //     return <PendingFounderDashboard />;
    //   case "pending":
    //     return <SubmittedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       case "rejectedReapply":
    //     return <RejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
    //   case "rejected":
    //     switch (foundDeat.founderStatus) {
    //       case "n/a":
    //         return <RejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       case "rejected":
    //         return <RejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       case "rejectedReapply":
    //         return <RejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       default:
    //         return <RejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />; 
    //     }
    //   case "unsubmitted":
    //     return <UnsubmittedFounderDashboard companyNameForDashboard={foundDeat.companyName} midApplication={foundDeat.midApplication} />;
    //   default:
    //     throw new Error('Unknown Application Status');
    // }
      // switch (memberApplicationStatus) {
    //   case "approved":
    //     switch (foundDeat.founderStatus) {
    //       case "n/a":
    //         return <ApprovedFounderDashboard />;
    //       case "ipExpertInvite":
    //         return <ExpertInviteDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       case "ipExpertInProgress":
    //       case "ipExpertRecommendation":
    //           return <ExpertMetDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       case "sowRequested":
    //         return <PreSowDashboard companyNameForDashboard={foundDeat.companyName} tier={3} percentage={20} firm={"Roland LLP"}/>;
    //       default:
    //         return <ApprovedFounderDashboard />;
    //     }
    //   case "flagged":  
    //     return <PendingFounderDashboard />;
    //   case "pending":
    //     return <SubmittedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
    //   case "rejected":
    //     switch (foundDeat.founderStatus) {
    //       case "n/a":
    //         return <RejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       case "rejected":
    //         return <RejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       case "rejectedReapply":
    //         return <RejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
    //       default:
    //         return <RejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />; 
    //     }
    //   case "unsubmitted":
    //     return <UnsubmittedFounderDashboard companyNameForDashboard={foundDeat.companyName} midApplication={foundDeat.midApplication} />;
    //   default:
    //     throw new Error('Unknown Application Status');
    // }

        switch (memberApplicationStatus) {
      case "approved":
        return <TempApprovedFounderDashboard companyNameForDashboard={foundDeat.companyName}/>;    
      case "pending":
        return <TempApprovedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
      case "rejectedReapply":
        return <TempRejectedReapplyFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
      case "pendingEditRequest":
        return <TempEditRequestFounderDashboard companyNameForDashboard={foundDeat.companyName} />;  
      case "rejected":
        switch (foundDeat.founderStatus) {
          case "n/a":
            return <TempRejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
          case "rejected":
            return <TempRejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
          case "rejectedReapply":
            return <TempRejectedReapplyFounderDashboard companyNameForDashboard={foundDeat.companyName} />;
          default:
            return <TempRejectedFounderDashboard companyNameForDashboard={foundDeat.companyName} />; 
        }
      case "unsubmitted":
        return <TempUnsubmittedFounderDashboard companyNameForDashboard={foundDeat.companyName} midApplication={foundDeat.midApplication} />;    
      default:
        throw new Error('Unknown Application Status');
    }
  }

  return (
    getFounderDashboardByApplicationStatus()
  )
}

export default FounderDashboard