import { FormData } from "../Components/Form/Form";
import instance from "../middleware/HTTPInterceptors";
import ErrorResponse from "../utilities/ErrorResponse";
import * as T from "../utilities/frontendTypes";
import { getAuthHeader } from "../utilities/helperFunctions";

/* 

  Dashboard Services

*/
export async function getDashboardData(): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/founders/dashboard-data`, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

/* 

  Founder Data Service

*/

export async function getCompleteFounderData(): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/founders/complete-founder-data`, config);

    if (res.status === 200) {
      return res.data;
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

/* 

  Program, Membership & Application Services

*/
export async function createFounderApplication(programId: T.ProgramId): Promise<T.FounderMembershipApplicationResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const data = {
      programId
    }
    
    const res = await instance.post(`/api/founders/application`, data, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getLatestMembershipApplication(): Promise<T.HTTPResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/founders/applications/latest-membership-application`, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getAllPrograms(): Promise<T.FounderProgramsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get("/api/founders/programs", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getProgramDetails(programId: T.ProgramId): Promise<T.ProgramDetailsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/founders/programs/${programId}`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function createFounderProgramApplication(programId: T.ProgramId, additionalData: FormData | undefined): Promise<T.FounderMembershipApplicationResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const data = {
      programId,
      additionalData
    }
    
    const res = await instance.post(`/api/founders/program-application`, data, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function founderProgramEnroll(programId: T.ProgramId): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const data = {
      programId
    }
    
    const res = await instance.post(`/api/founders/program-enrollment`, data, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getFounderApplicationHeaders(): Promise<T.FounderApplicationHeadersResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/founders/founder-applications", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getFounderProgramApplicationDetails(id: string): Promise<T.ApplicationDetailsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/founders/program-application/${id}`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getEnrolledPrograms(): Promise<T.EnrolledProgramsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/founders/enrolled-programs", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

/* 

  Service Provider Firms Services

*/
export async function getSPFirms(): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/founders/sp-firms`, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getSPFirmDetailsById(firmId: T.FirmId): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/founders/sp-firms/${firmId}`, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function sendHelpEmail(emailDetails: {message: string}): Promise<T.HTTPResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.put(`/api/founders/help`, emailDetails, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function sendContactEmail(emailDetails: {subject: string, message: string, email: string}): Promise<T.HTTPResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.put(`/api/public/contact-us`, emailDetails, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

/* 

  Portal Control Services

*/
export async function getNewMemberAcceptanceStatus(): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/founders/new-member-acceptance-status`, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getFounderStatus(): Promise<{success: boolean, founderStatus: string | null, adminNotes: string}> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/founders/get-founder-status`, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}


export async function pdfUpload(form: any): Promise<T.HTTPRequestSuccessFlag> {
  try {
    const config = {
      headers: await getAuthHeader(),
      'Content-Type': 'multipart/form-data'
    }
    const res = await instance.post('/api/founders/upload-file', form, config) 

    return res.data.success;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
};

export async function returnUserId(): Promise<{success: boolean, founderId: string }> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/founders/get-founder-id`, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getDownloadUrl(document: string): Promise<{success: boolean, url: string }> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/founders/get-pdf/${document}`, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getfounderDashboardDetails(): Promise<{success: boolean, dashboardDetails: T.FounderDashboardDetails }> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/founders/get-dashboard-details`, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}


export async function addIdAsync(): Promise<T.HTTPResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.put(`/api/founders/learning-id`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function editedApplicationChange(): Promise<T.FounderMembershipApplicationResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.post(`/api/founders/application-edit-request-update`, {}, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}
