import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./Pages/LandingPage/pages/index";
import Login from "./Pages/Login";
import { useContext, useEffect, useState } from "react";
import Register from "./Pages/Register";
import Header from "./Components/Header";
import MembersList from "./Pages/AdminPages/MembershipPages/MembersList";
import MemberApplications from "./Pages/AdminPages/MembershipPages/MemberApplications";
import MemberApplicationDetail from "./Pages/AdminPages/MembershipPages/MemberApplicationDetail";
import MemberDetails from "./Pages/AdminPages/MembershipPages/MemberDetails";
import { AuthContext, AuthContextType, LangContext, LangContextType } from "./utilities/customHooks";
import Dashboard from "./Pages/Dashboard";
import Profile from "./Pages/Profile";
import NotAuthorizedPage from "./Pages/NotAuthorizedPage";
import * as T from "./utilities/frontendTypes"
import LoadingIndicator from "./Components/LoadingIndicator";
import AllPrograms from "./Pages/AdminPages/ProgramsPages/AllPrograms";
import ProgramApplications from "./Pages/AdminPages/ProgramsPages/ProgramApplications";
import ProgramApplicationDetails from "./Pages/AdminPages/ProgramsPages/ProgramApplicationDetails";
import ProgramDetails from "./Pages/AdminPages/ProgramsPages/ProgramDetails";
import MyPrograms from "./Pages/AdminPages/ProgramsPages/MyPrograms";
import MyProgramDetails from "./Pages/AdminPages/ProgramsPages/MyProgramDetails";
import Modal from "react-modal";
import NewProgram from "./Pages/AdminPages/ProgramsPages/NewProgram";
// import NewAdmin from "./Pages/AdminPages/ProfilePages/NewAdmin";
import Toast from "./Components/Toast";
import PartnerAdminList from "./Pages/AdminPages/ProfilePages/PartnerAdminList";
import FounderProgramList from "./Pages/FounderPages/ProgramPages/FounderProgramList";
import FounderProgramDetails from "./Pages/FounderPages/ProgramPages/FounderProgramDetails";
import FounderApplications from "./Pages/FounderPages/ProgramPages/FounderApplications";
import EnrolledPrograms from "./Pages/FounderPages/ProgramPages/EnrolledPrograms";
import AdminVerification from "./Pages/AdminPages/ProfilePages/AdminVerification";
import Console from "./Pages/Dashboards/Console";
import ForgotPassword from "./Pages/ForgotPassword";
import PasswordReset from "./Pages/PasswordReset";
import NotFoundPage from "./Pages/NotFoundPage";
import NewFirm from "./Pages/AdminPages/ServiceProviderPages/NewFirm";
import FirmDetails from "./Pages/AdminPages/ServiceProviderPages/FirmDetails";
import FirmList from "./Pages/AdminPages/ServiceProviderPages/FirmList";
import FounderFirmList from "./Pages/FounderPages/ServiceProviderFirmPages/FirmList";
import FounderFirmDetails from "./Pages/FounderPages/ServiceProviderFirmPages/FirmDetails";
import LoadingOverlay from "./Components/LoadingOverlay";
import Settings from "./Pages/Settings";
import { applyTheme } from "./Theme/utilities";
import baseTheme from "./Theme/base";
import darkTheme from "./Theme/dark";
import { getIsOSDarkMode, getUserLanguage, checkIsOnline, checkUrlAndModifySpan } from "./utilities/helperFunctions";
import FounderProgramApplicationDetails from "./Pages/FounderPages/ProgramPages/FounderProgramApplicationDetails";
import Help from "./Pages/Help";
import Disconnected from "./Pages/Disconnected";
import { FAQ } from "./Pages/FounderPages/FAQ";
import { Resources } from "./Pages/FounderPages/Resources";
import CreateSOW from "./Pages/ServiceProviderPages/createSOW2";
import SowRequests from "./Pages/ServiceProviderPages/sowRequests";
import FounderAssesments from "./Pages/IpExpertPages/FounderAssesments";
import FounderAssesmentDetails from "./Pages/IpExpertPages/FounderAssesmentDetails";
import IpExpertVerification from "./Pages/IpExpertPages/IpExpertVerification";
import ServiceProviderVerification from "./Pages/ServiceProviderPages/ServiceProviderVerification";
import NewIpExpert from "./Pages/AdminPages/ProfilePages/NewIpExpert";
import NewServiceProviderFirm from "./Pages/AdminPages/ServiceProviderPages/NewFirm";
import NewServiceProvider from "./Pages/AdminPages/ProfilePages/NewServiceProvider";
import NewUser from "./Pages/AdminPages/ProfilePages/NewUser";
import PreInvite from "./Pages/AdminPages/IpExpertPages/PreInvite";
import InProgress from "./Pages/IpExpertPages/InProgress";
import Submitted from "./Pages/IpExpertPages/Submitted";
import ContactUs from "./Pages/ContactUs";
import NoProfile from "./Pages/AdminPages/MembershipPages/NoProfile";
import FounderServiceList from "./Pages/FounderPages/ProgramPages/FounderServiceList";
import EnrolledServices from "./Pages/FounderPages/ProgramPages/EnrolledServices";
import PreMeeting from "./Pages/AdminPages/IpExpertPages/PreMeeting";
import InProgressAdmin from "./Pages/AdminPages/IpExpertPages/InProgressAdmin";
import RecommendationAdmin from "./Pages/AdminPages/IpExpertPages/RecommendationAdmin";
import RecommendationAdminDetails from "./Pages/AdminPages/IpExpertPages/RecommendationAdminDetails";
import SubmittedFounderAssesmentDetails from "./Pages/IpExpertPages/SubmittedFounderAssesmentDetails";
import FounderServiceDetails from "./Pages/FounderPages/ProgramPages/FounderServiceDetails";
import CoachPreMeeting from "./Pages/AdminPages/GrowthCoachPages/PreMeeting";
import CoachInProgressAdmin from "./Pages/AdminPages/GrowthCoachPages/CoachInProgressAdmin";
import CoachRecommendationAdmin from "./Pages/AdminPages/GrowthCoachPages/CoachRecommendationAdmin";
import DueDilligence from "./Pages/IpExpertPages/DueDilligence";
import DueDiligenceAdmin from "./Pages/AdminPages/IpExpertPages/DueDiligenceAdmin";
import DueDiligenceAdminDetails from "./Pages/AdminPages/IpExpertPages/DueDiligenceAdminDetails";
import CoachInProgressAdminDetails from "./Pages/AdminPages/GrowthCoachPages/CoachInProgressAdminDetails";
import CoachPreMeetingAdminDetails from "./Pages/AdminPages/GrowthCoachPages/CoachPreMeetingAdminDetails";
import CoachRecommendationAdminDetails from "./Pages/AdminPages/GrowthCoachPages/CoachRecommendationAdminDetails";
import EipApplication from "./Pages/FounderPages/EipApplication";
import ProgramDetailsHome from "./Pages/FounderPages/ProgramDetails";
import PendingAppliactions from "./Pages/AdminPages/MembershipPages/PendingApplications";
import LearningPathway from "./Pages/FounderPages/ProgramPages/LearningPathway";
import Glossary from "./Pages/Glossary";
import HelpContact from "./Pages/HelpContactUs";
import GrowthCoachVerification from "./Pages/AdminPages/GrowthCoachPages/GrowthCoachVerification";
import GrowthCoachDetails from "./Pages/AdminPages/GrowthCoachPages/GrowthCoachDetails";
import GrowthCoachInProgress from "./Pages/AdminPages/GrowthCoachPages/GrowthCoachInProgress";
import GrowthCoachHoursReport from "./Pages/AdminPages/GrowthCoachPages/GrowthCoachHoursReport";

function App() {

  const { isAuthenticated, userRole, settings } = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional
  const pathname = useLocation().pathname;
  const [ tempAdminUserRole, setTempAdminUserRole ] = useState("tempAdmin" as T.UserRole) // [TODO] possibly refactor
  const [ tempExpertUserRole, setTempExpertUserRole ] = useState("tempExpert" as T.UserRole) // [TODO] possibly refactor
  const [ tempProviderUserRole, setTempProviderUserRole ] = useState("tempProvider" as T.UserRole) // [TODO] possibly refactor
  const [ tempGrowthCoachUserRole, setTempGrowthCoachUserRole ] = useState("tempGrowthCoach" as T.UserRole) // [TODO] possibly refactor
  const { langState, setLang } = useContext(LangContext) as LangContextType
  const [ isOnline, setIsOnline ] = useState<boolean>(true);
  useEffect(() => {
    const handleNetworkChange = async () => {
      setIsOnline(await checkIsOnline());
    }
    handleNetworkChange();
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, [pathname, navigator.onLine]);

  useEffect(() => {
      const lang = userRole === "partnerAdmin" || userRole === "superAdmin" ? "eng" : settings?.lang;
      if (lang === undefined) {
        setLang(langState)
      }
      let newLang = "eng" as T.LangState;
      switch(lang) {
        case "eng":
          newLang = "eng";
          break;
        case "fr":
          newLang = "fr";
          break;
        default: // undefined
          const userLang = getUserLanguage()
          if (userLang === "Fr-fr" || userLang === "fr" || userLang ===  "fr-fr"){
            newLang = "fr"
          }
          newLang = "eng";
      }
      setLang(newLang)
      const themeMode = settings?.themeMode;
      let theme = baseTheme;
      switch(themeMode) {
        case "light":
          theme = baseTheme;
          break;
        case "dark":
          theme = darkTheme;
          break;
        default: // undefined
          theme = getIsOSDarkMode() ? darkTheme : baseTheme; // if the Setting object is not set yetm apply theme based on OS setting
      }
      applyTheme(theme);
  }, [settings]);

  useEffect(() => {
    const currentUrl = window.location.pathname; 
    checkUrlAndModifySpan(currentUrl)
  }, [])
  
  function requireAuth(element: JSX.Element): JSX.Element {

    return isAuthenticated ? element : <Navigate to="/login" replace />;

  }

  function rolePermissionCheck(allowedRoles: T.UserRole[], element: JSX.Element): JSX.Element {

    if (userRole === null) {
      return <LoadingIndicator />
    }

    // if user's role is NOT allowed to access the route
    if (!allowedRoles.includes(userRole)) {
      return <Navigate to={"/unauthorized"} />
    }

    return element;
  }

  if (!isOnline) {
    return (
      <Disconnected />
    )
  }

  return (
    // [TODO] 
    <div className={`flex bg-background h-full w-full items-center relative overflow-hidden justify-start flex-col`}>
      <LoadingOverlay /> 
      <Toast />
      <Routes>
        <Route path="*" element={ <NotFoundPage /> } />
        <Route path="/unauthorized" element={ <NotAuthorizedPage /> } />
        <Route path="/" element={ <Home /> } />
        <Route path="/contact-us" element={ <ContactUs /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/register" element={ <Register /> } />
        <Route path="/passwordreset/:resetToken" element={ <PasswordReset /> } />
        <Route path="/forgot-password" element={ <ForgotPassword /> } />
        <Route path="dashboard" element={ requireAuth(<Dashboard />) } >
          <Route index element={<Console />} />
          <Route path="profile" element={ <Profile /> } />
          <Route path="admin-verification" element={ rolePermissionCheck(['tempAdmin', tempAdminUserRole], <AdminVerification setTempAdminUserRole = {setTempAdminUserRole}/>)} />
          <Route path="expert-verification" element={ rolePermissionCheck(['tempExpert', tempExpertUserRole], <IpExpertVerification setTempExpertUserRole = {setTempExpertUserRole}/>)} />
          <Route path="provider-verification" element={ rolePermissionCheck(['tempProvider', tempProviderUserRole], <ServiceProviderVerification setTempProviderUserRole = {setTempProviderUserRole}/>)} />
          <Route path="growth-coach-verification" element={ rolePermissionCheck(['tempGrowthCoach', tempGrowthCoachUserRole], <GrowthCoachVerification setTempGrowthCoachUserRole = {setTempGrowthCoachUserRole}/>)} />
          <Route path="new-user" element={ rolePermissionCheck(['partnerAdmin', 'superAdmin'], <NewUser />) } />
          <Route path="founders-no-profile" element={ rolePermissionCheck(['partnerAdmin', 'superAdmin'], <NoProfile />) } />
          <Route path="admin-list" element={ rolePermissionCheck(['superAdmin'], <PartnerAdminList />) }/> 
          <Route path="sp-firms" element={ rolePermissionCheck(['partnerAdmin', 'superAdmin'], <FirmList />) }/>
          <Route path="sp-firms/create" element={<NewFirm />} />
          <Route path="sp-firms/:firmId" element={<FirmDetails />} />
          <Route path="memberships" element={ rolePermissionCheck(['partnerAdmin', 'superAdmin'], <MembersList />) } />
          <Route path="pending-applications" element={ rolePermissionCheck(['partnerAdmin', 'superAdmin'], <PendingAppliactions />) } />
          <Route path="memberships/:memberId" element={ rolePermissionCheck(['partnerAdmin', 'superAdmin'], <MemberDetails />)} />
          <Route path="growth-coach-assessment/:memberId" element={ rolePermissionCheck(['partnerAdmin', 'growthCoach'], <GrowthCoachDetails />)} />    
          <Route path="growth-coach-in-progress" element={ rolePermissionCheck(['partnerAdmin', 'growthCoach'], <GrowthCoachInProgress />)} />       
          <Route path="growth-coach-reporting" element={ rolePermissionCheck(['partnerAdmin', 'growthCoach'], <GrowthCoachHoursReport />)} />                         
          <Route path="membership-applications" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <PendingAppliactions />) } />
          <Route path="membership-applications/:applicationId" element={<MemberApplicationDetail />} />
          <Route path="admin-expert-preinvite" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <PreInvite />)}/>
          <Route path="admin-expert-premeeting" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <PreMeeting />)}/>
          <Route path="admin-expert-in-progress" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <InProgressAdmin />)}/>
          <Route path="admin-expert-recommendations" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <RecommendationAdmin />)}>
            <Route path=":memberId" element={<RecommendationAdminDetails />}/>
          </Route>
          <Route path="admin-dd-application" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <DueDiligenceAdmin />)}>
            <Route path=":memberId" element={<DueDiligenceAdminDetails />}/>
          </Route>
          <Route path="admin-coach-premeeting" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <CoachPreMeeting />)}>
            <Route path=":memberId" element={<CoachPreMeetingAdminDetails />}/>
          </Route>
          <Route path="admin-coach-in-progress" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <CoachInProgressAdmin />)}>
            <Route path=":memberId" element={<CoachInProgressAdminDetails />}/>
          </Route>
          <Route path="admin-coach-recommendations" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <CoachRecommendationAdmin />)}>
            <Route path=":memberId" element={<CoachRecommendationAdminDetails />}/>
          </Route>
          <Route path="services" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <AllPrograms />)}>
            <Route path=":programId" element={<ProgramDetails />}/>
          </Route>
          <Route path="my-services" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <MyPrograms />)}>
            <Route path=":programId" element={<MyProgramDetails />}/>
            <Route path="new-service" element={<NewProgram />}/>
          </Route>
          {/* <Route path="my-service-applications" element={rolePermissionCheck(['partnerAdmin', 'superAdmin'], <ProgramApplications />)}>
            <Route path=":applicationId" element={<ProgramApplicationDetails />}/>
          </Route> */}
          <Route path="application" element={rolePermissionCheck(['startupFounder'], <EipApplication />)}/>
          <Route path="program-details" element={rolePermissionCheck(['startupFounder'], <ProgramDetailsHome />)}/>
          <Route path="education" element={rolePermissionCheck(['startupFounder'], <FounderServiceList />)}/>
          <Route path="learning-pathway" element={rolePermissionCheck(['startupFounder'], <LearningPathway />)}/>
          <Route path="founder-services/:programId" element={<FounderProgramDetails />}/>
          <Route path="enrolled-services" element={rolePermissionCheck(['startupFounder'], <EnrolledServices/>)}/>
          <Route path="enrolled-services/:programId" element={<FounderServiceDetails />}/>
          <Route path="founder-applications" element={rolePermissionCheck(['startupFounder'], <FounderApplications />)}>
            <Route path=":applicationId" element={<FounderProgramApplicationDetails />}/>
          </Route> 
          <Route path="founder-sp-firms" element={ rolePermissionCheck(['startupFounder'], <FounderFirmList />) } />
          <Route path="founder-sp-firms/:firmId" element={ rolePermissionCheck(['startupFounder'], <FounderFirmDetails />) } />
          <Route path="resources" element={ rolePermissionCheck(['startupFounder'], <Resources />) } />
          <Route path="faq" element={<FAQ />} />
          <Route path="expert-assessments" element={rolePermissionCheck(['ipExpert'], <FounderAssesments />)}/>
          <Route path="expert-assessments/:id" element={<FounderAssesmentDetails />}/>
          <Route path="in-progress-assesments" element={rolePermissionCheck(['ipExpert'], <InProgress />)}>
            <Route path=":id" element={<FounderAssesmentDetails />}/>
          </Route> 
          <Route path="submitted-assesments" element={rolePermissionCheck(['ipExpert'], <Submitted />)}>
            <Route path=":id" element={<SubmittedFounderAssesmentDetails />}/>
          </Route>  
          <Route path="in-dd-assesments" element={rolePermissionCheck(['ipExpert'], <DueDilligence />)}/>
          <Route path="sow-requests" element={rolePermissionCheck(['serviceProvider', 'superAdmin'], <SowRequests />)} />
          <Route path="sow-requests/:applicationId" element={rolePermissionCheck(['serviceProvider', 'superAdmin'], <CreateSOW />)} />
          <Route path="settings" element={<Settings />} />
          <Route path="help" element={rolePermissionCheck(['startupFounder'], <Help />)} />
          <Route path="help/glossary" element={rolePermissionCheck(['startupFounder'], <Glossary />)} />
          <Route path="help/contact-us" element={rolePermissionCheck(['startupFounder'], <HelpContact />)} />
        </Route>
      </Routes>
    </div>
  );
}

Modal.setAppElement('#root')

export default App;