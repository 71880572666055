import * as T from '../utilities/frontendTypes';
import Cookies from "universal-cookie";
import { getAuthHeader, getRouteNameFromUserRole } from "../utilities/helperFunctions";
import instance from '../middleware/HTTPInterceptors';
import ErrorResponse from '../utilities/ErrorResponse';
import { Address } from '../Components/Form/AddressFields';


export async function getUserInfo(): Promise<T.UserInfoResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/users/user-info", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
} 

export async function getUserProfile(userRole: T.UserRole): Promise<T.UserProfileResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const routeName = getRouteNameFromUserRole(userRole);

    const res = await instance.get(`/api/${routeName}/profile`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function updateUserProfile(userRole: T.UserRole, updatedProfile: T.AdminProfile | T.StartupFounderProfile | T.ServiceProviderProfile | T.TempAdminProfile | T.ExpertProfile | T.ServiceProviderProfile | T.TempGrowthCoachProfile ): Promise<T.UserProfileResponse> {
  console.log(userRole)
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const routeName = getRouteNameFromUserRole(userRole);
    const res = await instance.put(`/api/${routeName}/profile`, updatedProfile, config);
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export type RegisterInfo = {
  username: string,
  email: string,
  password: string,
  userRole: T.UserRole,
  userRegion: T.UserRegion,
  sLASigningTimestamp: Date,
  address: Address,
  settings: {
    lang: T.LangState
  }
}
export async function registerUser(reqBody: RegisterInfo): Promise<T.HTTPRequestSuccessFlag> {
  try {
    const res: T.LoginResponse = await instance.post("/api/auth/register", reqBody);
    if (!res.data.success){
      return res.data.success;
    }
    const cookie = new Cookies();
    cookie.set("authToken", res.data.token);

    return res.data.success;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function loginUser(reqBody: T.LoginInfo): Promise<T.HTTPRequestSuccessFlag> {
  try {
    
    const res: T.LoginResponse = await instance.post("/api/auth/login", reqBody);

    const cookie = new Cookies();
    cookie.set("authToken", res.data.token);
    return res.data.success;

  } catch (err: any) { // [TODO] look into a better pattern for handling err
    return err.response.data.error;
  }
}

export async function logoutUser() {

  try {

    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.delete("/api/auth/logout", config);

    const cookie = new Cookies();
    cookie.remove("authToken");

    return res.data.success;

  } catch (err: any) {
    return err.response;
  }
}

export async function checkIsUserAlive(): Promise<boolean> {

  try {
    const cookie = new Cookies();
    let accessToken = await cookie.get('authToken');
    const refreshToken = await cookie.get('refreshToken');

    if (!accessToken && !refreshToken) {
      return false;
    }

    if (!accessToken) {
      accessToken = await refreshAccessToken();
    }

    // not using the getAuthHeader helper function here to avoid having to get token twice
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }
    const res = await instance.get("/api/private", config);

    return res.data.success;

  } catch (err: any) {
    return false;
  }
}

export async function getRegionalMembershipId(): Promise<{success: boolean, membershipId: T.MembershipId}> {
  
  try {

    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/users/regional-membership-id", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) { 
    return err.response.data.error;
  }
}

export async function refreshAccessToken() {
  try {
    const res = await instance.get("/api/auth/refresh");
    const cookie = new Cookies();
    const newAccessToken = res.data.token;
    cookie.set("authToken", newAccessToken);
    return newAccessToken;
  } catch (err: any) {
    return err.response.data.error;
  }
}

export async function setCommonSettings(settings: T.Settings): Promise<T.HTTPRequestSuccessFlag> {
  try {

    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.put("/api/users/user-common-settings", settings, config);

    return res.data.success;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function pdfUpload(formData: FormData): Promise<T.HTTPRequestSuccessFlag> {
  try {
    const config = {
      headers: await getAuthHeader(),
      'Content-Type': 'multipart/form-data'
    }
    const res = await instance.post('/api/users/upload-pdf', formData, config) 

    return res.data.success;

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
};

// export async function updateUserLang(langState: T.LangState): Promise<T.HTTPRequestSuccessFlag> {
//   try {

//     const config = {
//       headers: await getAuthHeader(),
//     }

//     const res = await instance.put("/api/users/user-lang", langState, config);

//     return res.data.success;

//   } catch (err: any) {
//     throw new ErrorResponse(err.response.data.message, err.response.status);
//   }
// }

export async function getName(): Promise<{success: boolean, name: string}> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/users/get-name`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

